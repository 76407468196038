html,
body {
  padding: 0;
  margin: 0;
  background-color: #f1f1f1;
  font-family: monospace;
}

button:focus {
  outline: none;
}

.sortingVisualizer {
  width: 100vw;
  height: 100vh;
}

.header {
  width: 100%;
  height: 100px;
  background-color: #0d98ba;
  border-bottom: 1px solid #ffffff;
}

.header .headerBttns {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.header button {
  display: inline-block;
  margin-left: 50px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  color: black;
  font-size: 20px;
  font-weight: 700;
  transition: all 0.5s ease;
}

.header button:hover {
  cursor: pointer;
  box-shadow: 2px 2px 10px #000000;
}

.arrayControllers {
  display: flex;
  margin-top: 20px;
  margin-left: 550px;
}

.arrayControllers #velocity {
  margin-left: 50px;
}

.arrayControllers button {
  width: 120px;
  height: 60px;
  border: none;
  border-radius: 7px;
  background-color: #6495ed;
  color: #ffffff;
  opacity: 0.7;
  font-size: 18px;
  font-weight: 700;
  margin-left: 10px;
}

.arrayControllers button:hover {
  cursor: pointer;
  opacity: 1;
}

.arrayControllers #selected {
  opacity: 1;
}

.arrayControllers #restart {
  background-color: #ff7f50;
  border: 1px solid white;
  opacity: 1;
}

.arrayControllers .toggle {
  margin-top: 15px;
  margin-left: 20px;
  padding-top: 0;
}

.arrayControllers .toggle #soundLabel {
  position: absolute;
  margin-left: 10px;
  margin-top: 5px;
  color: #6495ed;
  font-weight: 800;
}

.arrayContainer {
  position: absolute;
  bottom: 100px;
  left: 15%;
}

.middle2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sortingVisualizer .bar {
  width: 10px;
  background-color: #add8e6;
  margin-left: 4px;
  display: inline-block;
  border-radius: 10px;
}

.algorithmInfo {
  position: absolute;
  top: 90%;
  bottom: 0%;
  left: 0%;
  width: 1425px;
  height: 70px;
  /* border: 5px solid #6495ed; */
  /* color: black; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d98ba;
}

.algorithmInfo div {
  margin-left: 30px;
  color: white;
  font-size: 25px;
  font-weight: 800;
}
